@import url('https://fonts.googleapis.com/css2?family=Sometype+Mono&display=swap');

* {
  font-family: 'Sometype Mono', monospace;
}

.App {
  text-align: center;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-nav {
  width: 100%;
  background-color: black;
  text-align: center;
  margin-bottom: 2.5%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}

li {
  float: left;
}

li a {
  display: block;
  color: lightcyan;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  border-bottom: 1.5px solid transparent;
}

li a:hover {
  color: pink;
  border-bottom: 1.5px solid pink;
}

.Page-header {
  /* color: black; */
  font-size: 5rem;
  background: pink;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.75px red;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  width: 350px;
  height: 250px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: lightpink;
  color: white;
  transform: rotateY(180deg);
}

.Nav-ul {
  margin: 0 37.5% 0 auto;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}

.Nav-list {
  float: left;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  display: grid;
}

.Question {
  background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 5%;
}

.Answer {
  background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Answer::before{
  content: "-";
  text-indent: -5px;
}

.Product-container {
  display: flex;
  padding: 15px;
  margin: auto;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Product-image {
  height: 40rem !important;
}

.Product {
  padding: inherit;
  width: 33.3%;
  height: auto;
  box-sizing: border-box;
}

.Custom-form {
  display: flex;
  padding: 15px;
  margin: auto;
  width: 50%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: lightcyan;
  border-radius:5%;
}

label {
  color: pink;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.5px red;
}

input[type=text], select {
  width: 95%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
}

textarea {
  width: 95%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
}

.Custom-description {
  width: 95%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
  resize: none;
}

.Help-text {
  color:pink;
  font-size: medium;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.35px red;
}

.Send-request {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color:pink;
}

.Send-request:hover {
  background-color: pink;
  color: white;
}

.Review-button {
  width: 40%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color:white;
  background: pink;
}

.Review-button:hover {
  background-color: white;
  color: pink;
}

.Review-container {
  display: flex;
  padding: 15px;
  margin: auto;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Review-card {
  margin-top: 2.5%;
  width: 30%;
  height: 30rem;
  left: 12%;
  background: linear-gradient(to bottom right, #7db9db 40%, #99dbcd 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(gray, 10%);
  perspective: 40px;
  padding: auto;
}

.Review-heart {
  width: 25%;
  align-self: center;
  display: inline;
}

.Reviewer-name {
  font-size: 140%;
  color: rgb(204, 117, 207);
}

.Review-text {
  color: white;
}